import axios from "axios";

import {
    CREATE,
    DELETE,
    ERROR,
    INDEX,
    REQUEST,
    SUCCESS,
    UPDATE
} from "../actions/api";

const state = {
    rules: [],
    status: "",
    error: ""
};

const getters = {
    isLoaded: state => state.status == "success",
    error: state => state.error
};

const mutations = {
    [REQUEST]: state => {
        state.status = "loading";
    },
    [SUCCESS]: (state, rules) => {
        state.status = "success";
        state.rules = rules;
    },
    [ERROR]: (state, err) => {
        state.status = "error";
        state.error = err;
    },
    [DELETE]: (state, rule) => {
        const i = state.rules.map(el => el.id).indexOf(rule.id);
        state.rules.splice(i, 1);
    },
    [CREATE]: (state, rule) => {
        state.rules = [...state.rules, rule];
    },
    [UPDATE]: (state, rule) => {
        // create new package with parameters
        let p = {};
        p = Object.assign(p, rule);

        // find id of package
        const i = state.rules.map(el => el.id).indexOf(rule.id);

        state.rules.splice(i, 1, p);
    }
};

const actions = {
    [INDEX]: ({ commit, rootGetters }, querry = null) => {
        return new Promise((resolve, reject) => {
            commit(REQUEST);
            axios
                .get(`/api/rules`, { params: querry })
                .then(resp => {
                    commit(SUCCESS, resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    console.log(err);
                    commit(ERROR, err.response.data.error);
                    reject(err);
                });
        });
    },
    [UPDATE]: ({ commit, rootGetters }, rule) => {
        return new Promise((resolve, reject) => {
            axios
                .put(`/api/rules/${rule.id}`, rule)
                .then(resp => {
                    commit(UPDATE, rule);
                    resolve();
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    [DELETE]: ({ commit, rootGetters }, rule) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/api/rules/${rule.id}`)
                .then(resp => {
                    commit(DELETE, rule);
                    resolve();
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    [CREATE]: ({ commit, rootGetters }, rule) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`/api/rules`, rule)
                .then(resp => {
                    commit(CREATE, resp.data);
                    resolve();
                })
                .catch(err => {
                    console.log(err);
                    reject(err.response.data);
                });
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};

import Vue from "vue";
import Router from "vue-router";
import app from "./routes/app";
import portal from "./routes/portal";
import landing from "./routes/landing";

Vue.use(Router);

function routes() {
    // return app;
    // get hostname
    let hostname = window.location.hostname;
    let parts = hostname.split(".");
    let protocol = window.location.protocol;
    console.log(protocol);

    // www.app.sjoeff.nl -> app
    // app.sjoeff.nl -> app

    // {name}.retour.sjoeff.nl -> portal
    // www.{name}.retour.sjoeff.nl -> portal

    // www.sjoeff.nl -> landing
    // sjoeff.nl -> landing

    console.log(parts);

    // remove www from parts
    if (parts[0] === "www") parts.shift();
    // remove top level domain
    let topleveldomain = parts.pop();

    // parts is [sjoeff]
    if (parts.length === 1) return landing;

    // parts is [app, sjoeff]
    if (parts.length === 2 && parts[0] === "app") return app;

    // parts is [{name}, retour, sjoeff]
    if (parts.length === 2 && parts[0] === "retour") return portal;

    // otherwise redirect to landing
    console.log(parts.at(-1) + "." + topleveldomain);
    window.location.href =
        protocol + "//" + parts.at(-1) + "." + topleveldomain;
    return null;
}

const router = new Router({
    mode: "history",
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    routes: routes()
});

export default router;

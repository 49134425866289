import { Model } from "@vuex-orm/core";

export default class Rule extends Model {
    static entity = "rules";

    // [
    //     {
    //         "id": 1,
    //         "name": "test",
    //         "active": 1,
    //         "user_id": 1,
    //         "conditions": [
    //             {
    //                 "id": 1,
    //                 "property": "shop",
    //                 "operator": "eq",
    //                 "value": "1",
    //                 "created_at": "2021-12-13T14:35:39.000000Z",
    //                 "updated_at": "2021-12-13T14:35:39.000000Z"
    //             }
    //         ],
    //         "actions": [
    //             {
    //                 "id": 1,
    //                 "rule_id": 1,
    //                 "identifier": "set_shipping_method",
    //                 "value": "1",
    //                 "created_at": "2021-12-13T14:35:39.000000Z",
    //                 "updated_at": "2021-12-13T14:35:39.000000Z"
    //             }
    //         ],
    //         "created_at": "2021-12-13T14:35:39.000000Z",
    //         "updated_at": "2021-12-13T14:35:39.000000Z"
    //     }
    // ]

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(""),
            active: this.boolean(),
            user_id: this.number(),

            conditions: this.attr(),
            actions: this.attr(),

            created_at: this.attr(null),
            updated_at: this.attr(null)
        };
    }

    static index() {
        return this.api().get("/api/rules");
    }

    static possible() {
        return this.api().get("/api/rules/possible");
    }

    static create(service) {
        return this.api().post("/api/rules", service);
    }

    static show(id) {
        return this.api().get(`/api/rules/${id}`);
    }

    static update(id, rule) {
        return this.api().put(`/api/rules/${id}`, rule);
    }

    static destroy(id) {
        return this.api().delete(`/api/rules/${id}`, {
            delete: id,
            save: false
        });
    }
}

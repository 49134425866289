import { Model } from "@vuex-orm/core";
import EmailTemplate from "./EmailTemplate";

export default class Brand extends Model {
    static entity = "brands";

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            name: this.attr(""),
            logo_url: this.attr(""),
            webshop_url: this.attr(""),
            portal_url: this.attr(""),
            primary_color: this.attr("#dedede"),
            secondary_color: this.attr("#ededed"),

            templates: this.hasMany(EmailTemplate, "brand_id", "id")
        };
    }

    static index() {
        return this.api().get("/api/brands");
    }

    static create(brand) {
        return this.api().post("/api/brands", brand);
    }

    static show(id) {
        return this.api().get(`/api/brands/${id}`);
    }

    static update(id, brand) {
        if (brand.logo) {
            let formData = new FormData();
            formData.append("logo", brand.logo);
            for (let key in brand) {
                if (key !== "logo" && brand[key])
                    formData.append(key, brand[key]);
            }
            brand = formData;
            //TODO: check
            // console.log(brand);
        }

        return this.api().post(`/api/brands/${id}`, brand);
    }

    static destroy(id) {
        return this.api().delete(`/api/brands/${id}`, {
            delete: id,
            save: false
        });
    }
}

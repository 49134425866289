import { Model } from "@vuex-orm/core";

export default class Integration extends Model {
    static entity = "integrations";
    static primaryKey = "code";

    static fields() {
        return {
            code: this.string(),
            display_name: this.string(),
            logo_url: this.string(),
            config: this.attr({}),
            data: this.attr({})
        };
    }
}

import { Model } from "@vuex-orm/core";

export default class Service extends Model {
    static entity = "services";

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(""),
            value: this.string(""),
            created_at: this.attr(null),
            updated_at: this.attr(null)
        };
    }

    static index() {
        return this.api().get("/api/services");
    }

    static create(service) {
        return this.api().post("/api/services", service);
    }

    static show(id) {
        return this.api().get(`/api/services/${id}`);
    }

    static update(id, service) {
        return this.api().put(`/api/services/${id}`, service);
    }

    static destroy(id) {
        return this.api().delete(`/api/services/${id}`);
    }
}

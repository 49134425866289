var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "side-bar-container" }, [
    _c(
      "div",
      { staticClass: "side-bar-logo" },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _c("img", { attrs: { src: "/images/logo.png" } })
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "left-aside__list px-0" },
      [
        _c(
          "router-link",
          {
            staticClass: "left-aside__item item__home",
            attrs: { to: "/shipping", tag: "li" }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "28.247",
                  height: "21.437",
                  viewBox: "0 0 28.247 21.437",
                  fill: "currentColor"
                }
              },
              [
                _c(
                  "g",
                  {
                    attrs: { id: "Shipping", transform: "translate(-0.5 -3)" }
                  },
                  [
                    _c("path", {
                      attrs: {
                        id: "Path_19548",
                        "data-name": "Path 19548",
                        d:
                          "M25.151,8.954H20.816V7.1A3.1,3.1,0,0,0,17.72,4a.619.619,0,1,0,0,1.238A1.86,1.86,0,0,1,19.577,7.1v10.59A3.1,3.1,0,0,0,17.162,20.1H9.608a3.092,3.092,0,0,0-6.041-.087,1.854,1.854,0,0,1-1.328-1.772V7.1A1.86,1.86,0,0,1,4.1,5.238.619.619,0,1,0,4.1,4,3.1,3.1,0,0,0,1,7.1V18.243a3.1,3.1,0,0,0,2.534,3.039,3.093,3.093,0,0,0,6.074.057h7.556a3.1,3.1,0,0,0,6.067,0h3.158a1.86,1.86,0,0,0,1.858-1.858V12.05a3.1,3.1,0,0,0-3.1-3.1ZM6.573,22.577A1.858,1.858,0,1,1,8.431,20.72a1.858,1.858,0,0,1-1.858,1.858Zm13.623,0a1.858,1.858,0,1,1,1.858-1.858A1.858,1.858,0,0,1,20.2,22.577Zm6.812-3.1a.62.62,0,0,1-.619.619H23.231a3.1,3.1,0,0,0-2.415-2.415V13.032a5.542,5.542,0,0,0,4.335,2.114h1.858Zm0-5.573H25.151a4.343,4.343,0,0,1-4.291-3.715h4.291a1.86,1.86,0,0,1,1.858,1.858Z",
                        transform: "translate(0 0.119)",
                        fill: "currentColor",
                        stroke: "currentColor",
                        "stroke-width": "1"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        id: "Path_19549",
                        "data-name": "Path 19549",
                        d:
                          "M11.773,8.892,9.916,10.749a.621.621,0,0,1-.877,0L7.181,8.892a.619.619,0,1,1,.876-.876l.8.8v-4.7a.619.619,0,1,1,1.238,0v4.7l.8-.8a.619.619,0,1,1,.876.876Z",
                        transform: "translate(1.431)",
                        fill: "currentColor",
                        stroke: "currentColor",
                        "stroke-width": "1"
                      }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n\t\t\t\t\t\t\t\t\t" +
                  _vm._s(_vm.$t("header.shipping")) +
                  "\n\t\t\t\t\t\t\t\t"
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "left-aside__item",
            attrs: { to: "/returns", tag: "li" }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  id: "Returns",
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "34.226",
                  height: "34.226",
                  viewBox: "0 0 34.226 34.226",
                  fill: "currentColor"
                }
              },
              [
                _c("path", {
                  attrs: {
                    id: "Path_19550",
                    "data-name": "Path 19550",
                    d: "M0,0H34.226V34.226H0Z",
                    fill: "none"
                  }
                }),
                _vm._v(" "),
                _c("circle", {
                  attrs: {
                    id: "Ellipse_758",
                    "data-name": "Ellipse 758",
                    cx: "3",
                    cy: "3",
                    r: "3",
                    transform: "translate(7.113 21.113)",
                    fill: "none",
                    stroke: "currentColor",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2"
                  }
                }),
                _vm._v(" "),
                _c("circle", {
                  attrs: {
                    id: "Ellipse_759",
                    "data-name": "Ellipse 759",
                    cx: "3",
                    cy: "3",
                    r: "3",
                    transform: "translate(21.113 21.113)",
                    fill: "none",
                    stroke: "currentColor",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2"
                  }
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "Path_19551",
                    "data-name": "Path 19551",
                    d:
                      "M5.852,22.113H3V6.426A1.426,1.426,0,0,1,4.426,5H17.261v8.556H10.13l2.852,2.852m0-5.7L10.13,13.556",
                    transform: "translate(1.278 2.13)",
                    fill: "none",
                    stroke: "currentColor",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2"
                  }
                }),
                _vm._v(" "),
                _c("line", {
                  attrs: {
                    id: "Line_3",
                    "data-name": "Line 3",
                    x2: "8",
                    transform: "translate(13.113 24.113)",
                    fill: "none",
                    stroke: "currentColor",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2"
                  }
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    id: "Path_19552",
                    "data-name": "Path 19552",
                    d: "M13,6h7.13l4.278,7.13v8.556H21.556",
                    transform: "translate(5.539 2.556)",
                    fill: "none",
                    stroke: "currentColor",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2"
                  }
                })
              ]
            ),
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("header.returns")) +
                "\n            "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "left-aside__item",
            attrs: { to: { name: "stats" }, tag: "li" }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "26",
                  height: "26",
                  viewBox: "0 0 27 27",
                  fill: "currentColor"
                }
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Statistics_white",
                      "data-name": "Statistics white",
                      transform: "translate(0)"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        id: "Path",
                        d:
                          "M10.6.837a1.378,1.378,0,0,1,.13.427l.362,5.382h0l.18,2.705a2.781,2.781,0,0,0,.13.82,1.356,1.356,0,0,0,1.307.82l8.654-.566a1.428,1.428,0,0,1,1.006.39,1.378,1.378,0,0,1,.415.792l.015.182a11.287,11.287,0,0,1-8.949,10.163A11.513,11.513,0,0,1,1.379,16.411a10.679,10.679,0,0,1-1.3-4.09A7.823,7.823,0,0,1,0,11.039,11.3,11.3,0,0,1,9.08.017,1.418,1.418,0,0,1,10.6.837Z",
                        transform: "translate(0 3.785)",
                        fill: "currentColor",
                        stroke: "currentColor"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        id: "Path-2",
                        "data-name": "Path",
                        d:
                          "M1.1,0A12.277,12.277,0,0,1,12.973,10.156l-.009.042h0l-.026.062,0,.169a1.04,1.04,0,0,1-.249.614,1.076,1.076,0,0,1-.6.353l-.142.02-9.985.647a1.192,1.192,0,0,1-.91-.295,1.149,1.149,0,0,1-.375-.7L.009,1.1a.159.159,0,0,1,0-.1A1.02,1.02,0,0,1,.345.273,1.06,1.06,0,0,1,1.1,0Z",
                        transform: "translate(13.027 0)",
                        fill: "currentColor",
                        stroke: "currentColor",
                        opacity: "0.4"
                      }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n\t\t\t\t\t\t\t\t\t " +
                  _vm._s(_vm.$t("header.statistics")) +
                  "\n\t\t\t\t\t\t\t\t"
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "left-aside__item",
            attrs: { to: { name: "packgo" }, tag: "li" }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24.383",
                  height: "24.379",
                  viewBox: "0 0 24.383 24.379",
                  fill: "currentColor"
                }
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Pack_and_Go",
                      "data-name": "Pack and Go",
                      transform: "translate(-0.996 -1)"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        id: "Path_19553",
                        "data-name": "Path 19553",
                        d:
                          "M25.379,6.541a.886.886,0,0,0-.066-.332.709.709,0,0,0,0-.078h0L23.1,1.7A1.108,1.108,0,0,0,22.055,1H3.217a1.108,1.108,0,0,0-1.108.842L1,6.275a.377.377,0,0,0,0,.111.776.776,0,0,0,0,.155V24.27a1.108,1.108,0,0,0,1.108,1.108H24.271a1.108,1.108,0,0,0,1.108-1.108V6.541ZM4.081,3.216H21.367l1.108,2.216H3.527ZM23.163,23.162H3.217V7.649H23.163Z",
                        transform: "translate(0)",
                        fill: "currentColor",
                        stroke: "currentColor"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        id: "Path_19554",
                        "data-name": "Path 19554",
                        d:
                          "M11.815,19.893a1.108,1.108,0,0,0,1.219,0l3.324-2.216a1.108,1.108,0,1,0-1.219-1.839l-1.607,1.108V10.108a1.108,1.108,0,0,0-2.216,0V16.9L9.709,15.793A1.108,1.108,0,0,0,8.49,17.632Z",
                        transform: "translate(0.766 0.865)",
                        fill: "currentColor",
                        stroke: "currentColor"
                      }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n\t\t\t\t\t\t\t\t\t" +
                  _vm._s(_vm.$t("header.packAndGo")) +
                  "\n\t\t\t\t\t\t\t\t"
              )
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "left-aside__version" }, [
      _c("p", [
        _vm._v(
          "\n            " +
            _vm._s(_vm.appVersion.vPrefix) +
            _vm._s(_vm.appVersion.separ) +
            _vm._s(_vm.appVersion.vCode) +
            _vm._s(_vm.appVersion.separ) +
            _vm._s(_vm.appVersion.vMajor) +
            _vm._s(_vm.appVersion.separ) +
            _vm._s(_vm.appVersion.vMinor) +
            _vm._s(_vm.appVersion.vName) +
            "\n          "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
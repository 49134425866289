import { Model } from "@vuex-orm/core";
import Package from "./Package";
import Status from "./Status";
import Vue from "vue";

export default class Label extends Model {
    static entity = "labels";

    static fields() {
        return {
            id: this.attr(null),
            shipment_id: this.string(null),
            waybill: this.string(null),
            carrier_code: this.string(null),
            carrier_title: this.string(null),
            current_status_code: this.attr(null),
            current_status: this.attr(null),
            tracking_url: this.string("#"),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            boxes: this.attr([]),
            notifications: this.attr([]),
            modifications: this.attr([]),

            package: this.belongsTo(Package, "shipment_id", "shipment_id"),
            statuses: this.morphMany(Status, "statusable_id", "statusable_type")
        };
    }

    static index(params) {
        // console.log('reoute', root.$route.name);
        return this.api()
            .get("/api/packages/labels", {
                params,
                dataKey: "data"
            })
            .then(resp => {
                Package.commit(state => {
                    Vue.set(
                        state.routePages,
                        root.$route.name + "_page_max",
                        resp.response.data.meta.last_page
                    );
                });
                return resp;
            });
    }

    static show(id) {
        return this.api().get(`/api/packages/labels/${id}`);
    }
}

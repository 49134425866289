import { Model } from "@vuex-orm/core";

export default class Address extends Model {
    static entity = "addresses";

    static fields() {
        return {
            id: this.attr(null),
            shop_id: this.attr("").nullable(),
            address: this.attr(""),
            address_2: this.attr(""),
            city: this.attr(""),
            country: this.attr(""),
            country_state: this.attr(""),
            house_number: this.attr(""),
            postal_code: this.attr(""),
            created_at: this.attr(null),
            updated_at: this.attr(null)
        };
    }
}

import { Model } from "@vuex-orm/core";

export default class ApiIntegrationConfig extends Model {
    static entity = "api_integrations_config";

    static primaryKey = "code";

    static fields() {
        return {
            code: this.string(""),
            display_name: this.string(""),
            description: this.string(""),
            can_disable: this.boolean(true),
            logo_url: this.string(""),
            fields: this.attr({}),
            created_at: this.attr(null),
            updated_at: this.attr(null)
        };
    }

    static index() {
        return this.api().get("/api/api-integrations/available");
    }

    static create(data) {
        return this.api().post("/api/api-integrations", data);
    }
}

import { Model } from "@vuex-orm/core";
import Address from "./Address";
import Token from "./Token";
import { app } from "../app";
import Brand from "./Brand";

export default class Shop extends Model {
    static entity = "shops";

    static state() {
        return {
            selected_id: 0
        };
    }

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            name: this.attr(""),
            shop_url: this.attr(""),
            brand_id: this.attr(null),
            address_id: this.attr(null),
            tokens: this.hasMany(Token, "shop_id"),
            address: this.belongsTo(Address, "address_id", "id"),
            brand: this.belongsTo(Brand, "brand_id", "id")
        };
    }

    // Endpoints
    static index() {
        return this.api()
            .get("/api/shops")
            .then(response => {
                // check if any store is present
                if (this.ensureShopCreated()) {
                    // if store is not selected
                    // select first one
                    if (!this.selectedShopId())
                        this.selectShop(Shop.query().first().id);
                }
                return response;
            });
    }

    static create(shop) {
        return this.api().post("/api/shops", shop);
    }

    static show(id) {
        return this.api().get(`/api/shops/${id}`);
    }

    static update(id, shop) {
        return this.api().put(`/api/shops/${id}`, shop);
    }

    static destroy(id) {
        return this.api().delete(`/api/shops/${id}`);
    }

    static ensureShopCreated() {
        if (!Shop.query().count()) {
            // // console.log('Shops count: 0 please create one shop');
            // if (app.$route.name !== "shops_new")
            //     app.$router.push({name: "shop_new"});
            // app.$toast.info("Let's create your first shop!");
            return false;
        }
        return true;
    }

    // Getters
    static selectedShopId() {
        this.ensureShopCreated();
        return this.store().state.entities.shops.selected_id;
    }

    // Mutators
    static selectShop(id) {
        // console.log("Selected shop " + id);
        Shop.commit(state => {
            state.selected_id = id;
        });
    }
}

import axios from "axios";
// import toast from 'vue-toast-notification';
import Package from "../../models/Package";
// import { app } from '../../app';
import { DELETE, ERROR, REQUEST, SUCCESS, UPDATE } from "../actions/api";

const uri_packgo = "/api/packgo";
const uri_printOptions = "/api/printOptions";

const state = {
    printers: [],
    status: "",
    error: "",
    ready: false,
    order: {},
    button_print_options: true,
    keyword_order_number: "",
    printOptions: {
        printer: "",
        paper_size: "A4",
        packing_slip_printer: ""
    },
    packgoOptions: {},
    paper_sizes: ["A4", "A6"],
    isLoaded_printOptions: false
};

const getters = {
    isLoaded: state => state.status == "success" || state.status == "error",
    error: state => state.error
};

const mutations = {
    [REQUEST]: state => {
        state.status = "loading";
    },
    [SUCCESS]: (state, order) => {
        state.status = "success";
        state.order = order;
        if (order.id) {
            state.packgoOptions.shop = order.shop_id;
            state.packgoOptions.service = order.shipment_id;
        }
    },
    [ERROR]: (state, err) => {
        state.status = "error";
        state.error = err;
        state.order = {};
    },
    READY_SET: (state, flag = true) => {
        state.ready = flag;
    },
    PRINTERS_SET: (state, printers) => {
        state.printers = printers;
    },
    BUTTON_PRINT_OPTIONS: (state, show) => {
        state.button_print_options = show;
    },
    STORE_PRINT_OPTIONS: (state, printOptions) => {
        state.printOptions = printOptions;
        state.isLoaded_printOptions = true;
    },
    KEYWORD_ORDER_NUMBER: (state, order) => {
        state.keyword_order_number = order;
    }
};

const actions = {
    [UPDATE]: ({ commit }, { order: pack }) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${uri_packgo}/` + pack.id, pack)
                .then(resp => {
                    Package.insert({ data: resp.data });
                    commit(SUCCESS, resp.data);
                    resolve();
                })
                .catch(err => {
                    console.log(err);
                    reject(err.response.data);
                });
        });
    },
    [DELETE]: ({ commit }, { order: pack }) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${uri_packgo}/` + pack.id)
                .then(resp => {
                    Package.delete(pack.id);
                    commit(SUCCESS, {});
                    resolve();
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    GET_ORDER: ({ commit }, { keyword_order_number }) => {
        return new Promise((resolve, reject) => {
            commit(REQUEST);
            axios
                .get(`${uri_packgo}/` + keyword_order_number)
                .then(resp => {
                    commit(SUCCESS, resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    console.log(err);
                    commit(ERROR, err.response.data.error);
                    reject(err);
                });
        });
    },
    SAVE_PRINT_OPTIONS: ({ commit }, { printOptions }) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${uri_printOptions}`, printOptions)
                .then(resp => {
                    commit("STORE_PRINT_OPTIONS", printOptions);
                    resolve();
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    SHOW_PRINT_OPTIONS: ({ commit }, { printOptions, printers }) => {
        return new Promise((resolve, reject) => {
            commit(REQUEST);
            axios
                .get(`${uri_printOptions}`)
                .then(resp => {
                    for (let printer of printers)
                        if (printer.default) {
                            printOptions.printer = printer.id;
                            break;
                        }
                    commit(
                        "STORE_PRINT_OPTIONS",
                        resp.data.paper_size ? resp.data : printOptions
                    );
                    resolve();
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};

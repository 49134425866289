import {
    AUTH_ERROR,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_REGISTER,
    AUTH_REQUEST,
    AUTH_SUCCESS
} from "../actions/auth";
import axios from "axios";
import { SUCCESS } from "../actions/api";
import Notification from "../../models/Notification";
import { app } from "../../app";
import User from "../../models/User";

const state = {
    token: localStorage.getItem("user-token") || "",
    user: "",
    status: "",
    error: ""
};
const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    error: state => state.error,
    user: state => state.user
};

const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, token) => {
        state.status = "success";
        state.token = token;
    },
    [AUTH_ERROR]: (state, err) => {
        state.status = "error";
        state.error = err;
    },
    [AUTH_LOGOUT]: state => {
        state.token = "";
        axios.defaults.headers.common["Authorization"] = "";
    },
    [SUCCESS]: (state, user) => {
        console.log(user);
        state.user = user;
        console.log(state.user);
        state.status = "success";
    }
};

const actions = {
    [AUTH_LOGIN]: ({ commit, dispatch }, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            axios
                .post("/api/auth/login", user)
                .then(resp => {
                    let token = `${resp.data.token_type} ${resp.data.access_token}`;
                    localStorage.setItem("user-token", token);
                    // Here set the header of your ajax library to the token value.
                    axios.defaults.headers.common["Authorization"] = token;
                    commit(AUTH_SUCCESS, token);
                    resolve(resp);
                })
                .catch(err => {
                    commit(AUTH_ERROR, err.response.data.error);
                    localStorage.removeItem("user-token");
                    reject(err);
                });
        });
    },
    [AUTH_REGISTER]: ({ commit, dispatch }, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            axios
                .post("/api/auth/register", user)
                .then(resp => {
                    let token = `${resp.data.token_type} ${resp.data.access_token}`;
                    localStorage.setItem("user-token", token);
                    // Here set the header of your ajax library to the token value.
                    axios.defaults.headers.common["Authorization"] = token;
                    commit(AUTH_SUCCESS, token);
                    resolve(resp);
                })
                .catch(err => {
                    commit(AUTH_ERROR, err.response.data.error);
                    localStorage.removeItem("user-token");
                    reject(err);
                });
        });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise(resolve => {
            commit(AUTH_LOGOUT);
            localStorage.removeItem("user-token");
            resolve();
        });
    },
    me({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/api/auth/user")
                .then(resp => {
                    commit(SUCCESS, resp.data);
                    Notification.insert({ data: resp.data.notifications });
                    User.insert({ data: resp.data });
                    if (resp.data.newAnnouncements) {
                        resp.data.newAnnouncements.forEach(announcement => {
                            let options = {
                                duration: 0,
                                queue: true,
                                onClick: () => {
                                    axios.post(
                                        `/api/announcements/${announcement.id}/read`
                                    );
                                }
                            };
                            let str =
                                announcement.header +
                                "<br>" +
                                announcement.body;
                            switch (announcement.type) {
                                case "alert":
                                    app.$toast.error(str, options);
                                    break;
                                default:
                                    app.$toast.info(str, options);
                                    break;
                            }
                        });
                    }
                    resolve(resp);
                })
                .catch(err => {
                    commit(AUTH_LOGOUT);
                    localStorage.removeItem("user-token");
                    reject(err);
                });
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

import shared from "./shared";

export default [
    {
        name: "landing",
        path: "/",
        meta: { domains: "sjoeff" },
        component: () => import("../views/Landing.vue")
    },
    ...shared
];

import { Model } from "@vuex-orm/core";
import Address from "./Address";

export default class Recipient extends Model {
    static entity = "recipients";

    static fields() {
        return {
            id: this.number(null),
            user_id: this.attr(null),
            address_id: this.number(null),
            phone_number: this.string(""),
            save_contact: this.attr(false),
            email: this.string(""),
            name: this.string(""),
            company_name: this.string("").nullable(),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            address: this.belongsTo(Address, "address_id")
        };
    }

    static index() {
        return this.api().get("/api/recipients");
    }

    static create(recipient) {
        return this.api().post("/api/recipients", recipient);
    }

    static show(id) {
        return this.api().get(`/api/recipients/${id}`);
    }

    static update(id, recipient, isContact = false) {
        return this.api().put(
            isContact ? `/api/contacts/${id}` : `/api/recipients/${id}`,
            recipient
        );
    }

    static destroy(id) {
        return this.api().delete(`/api/recipients/${id}`);
    }

    static indexContacts(query) {
        return this.api().get("/api/contacts", { params: { query: query } });
    }

    static createContact(contact) {
        return this.api().post("/api/contacts", contact);
    }

    static removeContact(id) {
        return this.api().delete(`/api/contacts/${id}`);
    }
}

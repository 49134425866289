import { Model } from "@vuex-orm/core";
import Integration from "./Integration";
import PickupPoint from "./PickupPoint";

export default class UserIntegrations extends Model {
    static entity = "user_integrations";

    static fields() {
        return {
            id: this.number(),
            user_id: this.number(),
            code: this.string(),
            active: this.boolean(false),
            client_id: this.string(),
            client_secret: this.string(),
            activated: this.boolean(false),

            config: this.belongsTo(Integration, "code", "code"),
            pickup_point: this.hasOne(PickupPoint, "integration", "code")
        };
    }

    static put(id, data) {
        return this.api().put(`/api/integrations/${id}`, data);
    }
}

import Vue from "vue";

import router from "./router";
import store from "./store";

import App from "./views/App";

// Toasts
import VueToast from "vue-toast-notification";
import "../css/toast.css";
// Tooltips
import { Dropdown, Menu, Tooltip, VClosePopper, VTooltip } from "v-tooltip";
// import "../css/tooltip.css";
import "v-tooltip/dist/v-tooltip.css";
// Loader
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import LoaderComponent from "./components/loader";
// Translations
import VueI18n from "vue-i18n";
// import messages from "./translations.js";
import en from "./locales/en/all.json";
import nl from "./locales/nl/all.json";
import de from "./locales/de/all.json";
import fr from "./locales/fr/all.json";
import it from "./locales/it/all.json";
import pl from "./locales/pl/all.json";
import ru from "./locales/ru/all.json";
import uk from "./locales/uk/all.json";
// Color picker
import { Compact } from "vue-color";
import axios from "axios";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

import Dayjs from "vue-dayjs";

Vue.use(VueToast);

Vue.directive("tooltip", VTooltip);
Vue.directive("close-popper", VClosePopper);

Vue.component("VDropdown", Dropdown);
Vue.component("VTooltip", Tooltip);
Vue.component("VMenu", Menu);

Vue.extend(LoaderComponent);
Vue.use(
    Loading,
    {
        backgroundColor: "#fff",
        opacity: 1,
        lockScroll: true,
        isFullPage: false
    },
    { default: new Vue().$createElement(LoaderComponent) }
);

let messages = {
    en,
    nl,
    uk,
    de,
    fr,
    it,
    pl,
    ru
};
Vue.use(VueI18n);
console.log(messages);
const i18n = new VueI18n({
    locale: localStorage.getItem("locale") ?? "nl", // set locale
    messages // set locale messages
});

Vue.component("compact-picker", Compact);

const token = localStorage.getItem("user-token");
if (token) {
    console.log("Token exists");
    axios.defaults.headers.common["Authorization"] = token;
}

Vue.use(Dayjs, {
    lang: Object.keys(messages)
    /**
     * addon filters { key: filter name }
     * if set {} will only dayjs base filter can use.
     */
    // filters?: {
    //     ago: 'ago',
    //     ...
    // },
    /**
     * addon directives { key: directives name }
     * set {} to disable.
     */
    // directives?: {
    //     countdown: 'countdown',
    //     ...
    // }
});

const app = new Vue({
    el: "#app",
    router,
    store,
    components: { App },
    i18n
});

window.root = app;

extend("required", {
    ...required,
    message: "{_field_} is required"
});

axios.interceptors.response.use(undefined, err => {
    if (err.response && err.response.status && err.response.status === 405) {
        app.$toast.error("This method is not allowed");
    }
    if (err.response && err.response.status && err.response.status === 422) {
        app.$toast.error(err.response.data.message);
    }
    return Promise.reject(err);
});

export { app };

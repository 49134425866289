import { Model } from "@vuex-orm/core";

export default class Box extends Model {
    static entity = "boxes";

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            name: this.attr(""),
            width: this.number(),
            height: this.number(),
            length: this.number()
        };
    }

    // Endpoints
    static index() {
        return this.api().get("/api/boxes");
    }

    static create(box) {
        return this.api().post("/api/boxes", box);
    }

    static show(id) {
        return this.api().get(`/api/boxes/${id}`);
    }

    static update(id, box) {
        return this.api().put(`/api/boxes/${id}`, box);
    }

    static destroy(id) {
        return this.api().delete(`/api/boxes/${id}`, {
            delete: id,
            save: false
        });
    }
}

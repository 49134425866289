<template>
    <div :class="{'neu-box':status!=null}" class="text-center">
        <div class="loader m-auto">
            <span class="elem"></span>
            <span class="elem"></span>
            <span class="elem"></span>
            <span class="elem"></span>
            <span class="elem"></span>
        </div>
        <div v-if="status!=null" class="pt-3 fw-bold">{{ status.loadingStatus }}</div>
    </div>
</template>
<script>
export default {
    props: {
        status: Object
    }
};
</script>
<style scoped>
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 54px;
    font-size: 0;
}

.loader > .elem {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    background: #5898f5;
    border-radius: 50px;
    -webkit-animation: anim-loader 1s ease-in-out infinite;
    animation: anim-loader 1s ease-in-out infinite;
}

.loader > .elem:nth-child(2) {
    margin-left: 12px;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.loader > .elem:nth-child(3) {
    margin-left: 12px;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.loader > .elem:nth-child(4) {
    margin-left: 12px;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.loader > .elem:nth-child(5) {
    margin-left: 12px;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

@-webkit-keyframes anim-loader {
    0% {
        height: 12px;
        box-shadow: none;
    }
    30% {
        height: 45px;
        box-shadow: -3px -3px 5px 0px rgba(255, 255, 255, 0.7),
        3px 3px 5px 0px rgba(0, 0, 0, 0.2);
    }
    60% {
        height: 12px;
        box-shadow: none;
    }
}

@keyframes anim-loader {
    0% {
        height: 12px;
        box-shadow: none;
    }
    30% {
        height: 45px;
        box-shadow: -3px -3px 5px 0px rgba(255, 255, 255, 0.7),
        3px 3px 5px 0px rgba(0, 0, 0, 0.2);
    }
    60% {
        height: 12px;
        box-shadow: none;
    }
}
</style>

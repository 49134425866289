import { Model } from "@vuex-orm/core";

export default class Printer extends Model {
    static entity = "printers";
    static primaryKey = "deviceId";

    static state() {
        return {
            // indicate if printer app is active
            serverActive: false,

            defaultPrinter: localStorage.getItem("default_printer_id") || ""
        };
    }

    static fields() {
        return {
            deviceId: this.attr(null),
            name: this.attr(null)
        };
    }

    static print(pack_id) {
        return new Promise((resolve, reject) => {
            if (this.store().state.entities.printers.serverActive)
                this.axios
                    .post("http://127.0.0.1:1337/print", null, {
                        params: {
                            url: `${window.location.protocol}//${window.location.host}/api/packages/${pack_id}/labels/download`,
                            printer: this.defaultPrinter()
                        }
                    })
                    .then(() => {
                        resolve("success");
                    })
                    .catch(err => {
                        reject(err);
                    });
            else {
                reject();
            }
        });
    }

    static printers() {
        const promise = this.api().get("http://127.0.0.1:1337/printers");
        promise.then(() => {
            if (!this.defaultPrinter())
                this.setDefaultPrinter(Printer.query().first().deviceId);

            Printer.commit(state => {
                state.serverActive = true;
            });
        });
        promise.catch(err => {
            Printer.commit(state => {
                state.serverActive = false;
            });
        });
        return promise;
    }

    // Getters
    static serverActive() {
        return this.store().state.entities.printers.serverActive;
    }

    static defaultPrinter() {
        return this.store().state.entities.printers.defaultPrinter;
    }

    static setDefaultPrinter(printer_id) {
        Printer.commit(state => {
            state.defaultPrinter = printer_id;
        });
        localStorage.setItem("default_printer_id", printer_id);
    }
}

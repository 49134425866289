var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 d-flex justify-content-center" }, [
    _c("div", { staticClass: "spinner-border", attrs: { role: "status" } }, [
      _c("span", { staticClass: "visually-hidden" }, [
        _vm._v(_vm._s(_vm.$t("actions.loading")))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "d-flex h-100" },
        [
          _c("sidebar-component"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-container d-flex flex-column" },
            [
              _c("header-component", { attrs: { meta: this.$route.meta } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "page-content" },
                [
                  _vm.shopsLoaded && _vm.settingsLoaded
                    ? _c("router-view")
                    : _c("spinner-component")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
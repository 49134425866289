import { Model } from "@vuex-orm/core";
import ApiIntegrationConfig from "./ApiIntegrationConfig";

export default class ApiIntegration extends Model {
    static entity = "api_integrations";

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(""),
            code: this.attr(""),
            name: this.attr(""),
            data: this.attr({}),
            config: this.belongsTo(ApiIntegrationConfig, "code", "code"),
            created_at: this.attr(null),
            updated_at: this.attr(null)
        };
    }

    static index() {
        return this.api().get("/api/api-integrations");
    }

    static edit(integration) {
        return this.api().put(
            "/api/api-integrations/" + integration.id,
            integration
        );
    }
    static destroy(id) {
        return this.api().post(`/api/api-integrations/destroy/${id}`, {
            delete: id,
            save: false
        });
    }
}

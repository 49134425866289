import shared from "./shared";

export default [
    {
        path: "/:portalName",
        name: "portal",
        redirect: { name: "return_portal" },
        component: () => import("../views/portal"),
        children: [
            {
                path: "/:portalName/rp",
                name: "return_portal",
                component: () => import("../views/portal/ReturnPortal.vue")
            },
            {
                path: "/:portalName/rp/reasons",
                name: "return_portal_reasons",
                component: () => import("../views/portal/Reasons.vue")
            },
            {
                path: "/:portalName/rp/refund",
                name: "return_portal_refund",
                component: () => import("../views/portal/Refund.vue")
            },
            {
                path: "/:portalName/rp/service",
                name: "return_portal_service",
                component: () => import("../views/portal/Service.vue")
            },
            {
                path: "/:portalName/rp/summary",
                name: "return_portal_summary",
                component: () => import("../views/portal/Summary.vue")
            }
        ]
    },
    ...shared
];

import { Model } from "@vuex-orm/core";

export default class Ticket extends Model {
    static entity = "tickets";

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            subject: this.string(""),
            is_resolved: this.boolean(false),
            messages: this.attr([]),

            created_at: this.string(null),
            updated_at: this.string(null)
        };
    }

    static index() {
        return this.api().get("/api/tickets");
    }

    static storeNewTicket(subject, message) {
        return this.api().post("/api/tickets", {
            subject: subject,
            message: message
        });
    }

    static show(id) {
        return this.api().get("/api/tickets/" + id);
    }

    static respond(id, message) {
        return this.api().post("/api/tickets/" + id + "/messages", {
            message: message
        });
    }
}

import { Model } from "@vuex-orm/core";
import UserSettings from "./UserSettings";
import UserIntegrations from "./UserIntegrations";
import Shop from "./Shop";
import Brand from "./Brand";

export default class User extends Model {
    static entity = "users";

    static state() {
        return {
            token: localStorage.getItem("user-token") || ""
        };
    }

    static fields() {
        return {
            id: this.number(),
            name: this.string(),
            email: this.string(),
            email_verified_at: this.attr(),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            user_settings: this.hasMany(UserSettings, "user_id", "id"),
            integrations: this.hasMany(UserIntegrations, "user_id", "id"),
            shops: this.hasMany(Shop, "user_id", "id"),
            brands: this.hasMany(Brand, "user_id", "id")
        };
    }

    static setToken(token) {
        localStorage.setItem("user-token", token);
        this.axios.defaults.headers.common["Authorization"] = token;
        User.commit(state => {
            state.token = token;
        });
        console.log("token is set");
    }

    static login(email, password) {
        if (localStorage.getItem("user-token")) return this.me();
        return this.api().post(
            "/api/auth/login",
            { email: email, password: password },
            {
                dataTransformer: ({
                    data: { token_type, access_token, user }
                }) => {
                    this.setToken(`${token_type} ${access_token}`);
                    return user;
                }
            }
        );
    }

    static register(user) {
        if (localStorage.getItem("user-token")) throw "Already logged in";
        return this.api().post("/api/auth/register", user, {
            dataTransformer: ({ data: { token_type, access_token, user } }) => {
                this.setToken(`${token_type} ${access_token}`);
                return user;
            }
        });
    }

    static me() {
        return this.api().get("/api/auth/user");
    }

    static logout() {
        this.store().dispatch("entities/deleteAll");
        return new Promise(resolve => {
            User.commit(state => {
                state.token = "";
            });
            // store.dispatch('entities/deleteAll');
            // User.deleteAll();
            this.axios.defaults.headers.common["Authorization"] = "";
            localStorage.removeItem("user-token");
            resolve();
        });
    }

    static isAuthenticated() {
        return !!this.store().state.entities.users.token;
    }
}

import { Model } from "@vuex-orm/core";

export default class Item extends Model {
    static entity = "items";

    static fields() {
        return {
            id: this.attr(null),
            package_id: this.attr(null),
            name: this.attr(""),
            quantity: this.attr(null),
            sku: this.attr(""),
            weight: this.attr(null),
            height: this.attr(null),
            length: this.attr(null),
            width: this.attr(null),
            type: this.attr(""),
            stackable: this.attr(false),
            value: this.attr(false),
            value_currency: this.attr(false),
            created_at: this.attr(null),
            updated_at: this.attr(null)
        };
    }

    static destroy(package_id, id) {
        return this.api().delete(`/api/packages/${package_id}/items/${id}`, {
            delete: id,
            save: false
        });
    }
}

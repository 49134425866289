import { Model } from "@vuex-orm/core";
import Address from "./Address";
import Label from "./Label";
import Status from "./Status";
import Recipient from "./Recipient";
import Shop from "./Shop";
import Item from "./Item";
import Vue from "vue";
import axios from "axios";

export default class Package extends Model {
    static entity = "packages";

    static state() {
        return {
            routePages: {},
            autoPrintIds: []
        };
    }

    static fields() {
        return {
            id: this.uid(null),
            errors: this.attr([]),
            shop_id: this.attr(null),
            recipient_id: this.attr(null),
            shipment_id: this.attr(null),
            order_number: this.attr(""),
            is_return: this.attr(false),
            service_code: this.attr(null),
            current_status_code: this.attr(null),
            current_status: this.attr(null),
            integration: this.attr(),
            service: this.attr(),
            packaging: this.attr(),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            count_labels: this.attr(null),

            already_exist_order: this.attr(false),

            recipient: this.belongsTo(Recipient, "recipient_id"),
            shop: this.belongsTo(Shop, "shop_id"),
            labels: this.hasMany(Label, "shipment_id", "shipment_id"),
            statuses: this.morphMany(
                Status,
                "statusable_id",
                "statusable_type"
            ),
            items: this.hasMany(Item, "package_id"),
            sender_address: this.belongsTo(Address, "sender_address_id"),
            pickup_point: this.attr(null)
        };
    }

    static index(shop_id, params) {
        let req = this.api().get("/api/packages", {
            params: params,
            dataKey: "data"
        });
        req.then(resp => {
            Package.commit(state => {
                Vue.set(
                    state.routePages,
                    root.$route.name + "_page_max",
                    resp.response.data.meta.last_page
                );
            });
        });
        return req;
    }

    static create(shop_id, pack) {
        return this.api().post("/api/shops/" + shop_id + "/packages", pack);
    }

    static findByOrderAndPostal(portal, order_number, postal_code) {
        return this.api().get("/api/portals/" + portal + "/order", {
            params: {
                order_number: order_number,
                postal_code: postal_code
            }
        });
    }

    static show(shop_id, id) {
        return this.api().get("/api/shops/" + shop_id + "/packages/" + id);
    }

    static showByOrderNumber(order_number) {
        return this.api().get("/api/packgo/" + order_number);
    }

    static update(shop_id, id, pack) {
        return this.api().put(
            "/api/shops/" + shop_id + "/packages/" + id,
            pack
        );
    }

    static destroy(shop_id, id) {
        return this.api().delete("/api/shops/" + shop_id + "/packages/" + id);
    }

    static indexAll(params) {
        return this.api().get("/api/packages", {
            params: params,
            dataKey: "data"
        });
    }

    static createLabel(id, sync = false, count_labels = "0") {
        console.log("createLabel", id, sync, count_labels);
        return this.api().post("/api/packages/" + id + "/labels/create", null, {
            params: { sync: sync, count_labels: count_labels }
        });
    }

    static lastPage() {
        return this.store().state.entities.packages.last_page;
    }

    static currentPage() {
        return this.store().state.entities.packages.current_page;
    }

    static createMultipleLabels(packageIds, autoPrint = false) {
        let resp = axios.post("/api/packages/labels/create", {
            packages: packageIds
        });
        resp.then(resp => {
            Package.insert({ data: [...resp.data.data] });
            console.log(resp.data.data);

            if (autoPrint) {
                // if this packages should be printed automatically, add them to the end of the array
                Package.commit(state => {
                    Vue.set(state, "autoPrintIds", [
                        ...state.autoPrintIds,
                        ...resp.data.data.map(item => item.id)
                    ]);
                });
            }
        });
        return resp;
    }
}

import Hub from "../views/Hub";
import { authCheck, notAuthCheck } from "./guards";
import shared from "./shared";

export default [
    {
        path: "/",
        component: Hub,
        meta: { domains: "app.sjoeff" },
        beforeEnter: authCheck,
        children: [
            {
                path: "shipping",
                alias: [""],
                component: () => import("../views/Shipping.vue"),
                children: [
                    {
                        path: "list",
                        alias: [""],
                        component: () => import("../views/ShippingList.vue"),
                        children: [
                            {
                                path: "",
                                name: "hub",
                                redirect: { name: "shipping_incoming" }
                            },
                            {
                                path: "incoming",
                                meta: {
                                    title: "meta.title.incoming",
                                    additionalLinks: [
                                        {
                                            name: "meta.button.newShipment",
                                            routeName: "shipping_new"
                                        }
                                    ]
                                },
                                name: "shipping_incoming",
                                component: () =>
                                    import("../views/tabs/IncomingOrders.vue")
                            },
                            {
                                path: "created",
                                name: "shipping_created",
                                meta: { title: "meta.title.created" },
                                component: () =>
                                    import("../views/tabs/CreatedLabels.vue")
                            },
                            {
                                path: "shipped",
                                name: "shipping_shipped",
                                meta: { title: "meta.title.shipped" },
                                component: () =>
                                    import("../views/tabs/Shipped.vue")
                            },
                            {
                                path: "canceled",
                                name: "shipping_canceled",
                                meta: { title: "meta.title.canceled" },
                                component: () =>
                                    import(
                                        "../views/tabs/CanceledShippings.vue"
                                    )
                            }
                        ]
                    },
                    {
                        path: "new",
                        name: "shipping_new",
                        meta: { title: "meta.button.newShipment" },
                        component: () => import("../views/tabs/NewShipping.vue")
                    },
                    {
                        path: "import",
                        name: "import_packages",
                        meta: { title: "meta.title.import" },
                        component: () =>
                            import("../views/tabs/ImportPackages.vue")
                    },
                    {
                        path: "shops/new",
                        name: "shop_new",
                        meta: { title: "meta.button.newShop" },
                        component: () => import("../views/NewShop.vue")
                    },
                    {
                        path: ":id/details",
                        name: "package_details",
                        meta: { title: "meta.title.labelDetails" },
                        component: () => import("../views/Label.vue")
                    }
                ]
            },
            {
                path: "returns",
                alias: [""],
                component: () => import("../views/Shipping.vue"),
                children: [
                    {
                        path: "list",
                        alias: [""],
                        component: () => import("../views/ReturnsList.vue"),
                        children: [
                            {
                                path: "",
                                name: "returns",
                                redirect: { name: "returns_incoming" }
                            },
                            {
                                path: "incoming",
                                name: "returns_incoming",
                                component: () =>
                                    import("../views/tabs/IncomingReturns.vue"),
                                meta: {
                                    title: "meta.title.returns.incoming",
                                    additionalLinks: [
                                        {
                                            name: "meta.button.newReturn",
                                            routeName: "returns_new"
                                        }
                                    ]
                                }
                            },
                            {
                                path: "notprocessed",
                                name: "returns_notprocessed",
                                component: () => import("../views/DevPage.vue"),
                                meta: {
                                    title: "meta.title.returns.notprocessed",
                                    additionalLinks: [
                                        {
                                            name: "meta.button.newReturn",
                                            routeName: "returns_new"
                                        }
                                    ]
                                }
                            },
                            {
                                path: "canceled",
                                name: "returns_canceled",
                                component: () => import("../views/DevPage.vue"),
                                meta: {
                                    title: "meta.title.returns.canceled",
                                    additionalLinks: [
                                        {
                                            name: "meta.button.newReturn",
                                            routeName: "returns_new"
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: "returns/new",
                name: "returns_new",
                component: () => import("../views/NewReturn.vue"),
                meta: { title: "meta.buttons.newReturn" }
            },
            {
                path: "stats",
                name: "stats",
                component: () => import("../views/settings/Stat.vue"),
                meta: { title: "meta.title.statistics" }
            },
            {
                path: "packgo",
                name: "packgo",
                component: () => import("../views/packgo/index.vue"),
                meta: { title: "meta.title.packAndGo" }
            },
            {
                path: "notifications",
                name: "notifications",
                component: () => import("../views/Notifications.vue"),
                meta: { title: "meta.title.notifications" }
            },
            {
                path: "settings",
                component: () => import("../views/Settings.vue"),
                children: [
                    {
                        path: "",
                        name: "settings",
                        redirect: { name: "settings_account" }
                    },
                    {
                        path: "package",
                        name: "package_settings",
                        component: () =>
                            import("../views/settings/Package.vue"),
                        meta: { title: "meta.title.settings.package" }
                    },
                    {
                        path: "portal",
                        name: "settings_portal",
                        component: () => import("../views/settings/Portal.vue"),
                        meta: { title: "meta.title.settings.portal" }
                    },
                    {
                        path: "account",
                        name: "settings_account",
                        component: () =>
                            import("../views/settings/Account.vue"),
                        meta: { title: "meta.title.settings.account" }
                    },
                    {
                        path: "printer",
                        name: "settings_printer",
                        component: () =>
                            import("../views/settings/Printer.vue"),
                        meta: { title: "meta.title.settings.printer" }
                    },
                    {
                        path: "shops",
                        name: "settings_shops",
                        component: () => import("../views/settings/Shops.vue"),
                        meta: {
                            title: "meta.title.settings.shop",
                            additionalLinks: [
                                {
                                    name: "meta.button.newShop",
                                    routeName: "shop_new"
                                }
                            ]
                        }
                    },
                    {
                        path: "brands",
                        name: "settings_brands",
                        component: () => import("../views/settings/Brands.vue"),
                        meta: {
                            title: "meta.title.settings.brand",
                            additionalLinks: [
                                {
                                    name: "meta.button.newBrand",
                                    routeName: "brand_new"
                                }
                            ]
                        }
                    },
                    {
                        path: "brands/new",
                        name: "brand_new",
                        meta: { title: "meta.button.newBrand" },
                        component: () => import("../views/NewBrand.vue")
                    },
                    {
                        path: "rules",
                        name: "settings_rules",
                        component: () => import("../views/settings/Rules.vue"),
                        meta: {
                            title: "meta.title.settings.rules",
                            additionalLinks: [
                                {
                                    name: "meta.button.newRule",
                                    routeName: "rule_new"
                                }
                            ]
                        }
                    },
                    {
                        path: "defaults",
                        name: "settings_defaults",
                        component: () =>
                            import("../views/settings/ShippingDefault.vue"),
                        meta: { title: "meta.title.settings.defaults" }
                    },
                    {
                        path: "contacts",
                        name: "settings_contacts",
                        component: () =>
                            import("../views/settings/Contacts.vue"),
                        meta: {
                            title: "meta.title.settings.contacts",
                            additionalLinks: [
                                {
                                    name: "meta.button.newContact",
                                    routeName: "contact_new"
                                }
                            ]
                        }
                    },
                    {
                        path: "contacts/new",
                        name: "contact_new",
                        component: () =>
                            import("../views/settings/NewContact.vue"),
                        meta: {
                            title: "meta.title.settings.contacts"
                        }
                    },
                    {
                        path: "rules/new",
                        name: "rule_new",
                        component: () => import("../views/NewRule.vue"),
                        meta: { title: "meta.button.newRule" }
                    },
                    {
                        path: "rules/:id",
                        name: "rule",
                        component: () => import("../views/EditRule.vue"),
                        meta: { title: "meta.title.settings.ruleDetails" }
                    },
                    {
                        path: "shops/:id",
                        name: "shop",
                        component: () => import("../views/settings/Shop.vue"),
                        meta: {
                            title: "meta.title.settings.shopDetails",
                            additionalLinks: [
                                {
                                    name: "meta.button.newToken",
                                    routeName: "token_new"
                                }
                            ]
                        }
                    },
                    {
                        path: "shops/:id/tokens/new",
                        name: "token_new",
                        component: () => import("../views/NewToken.vue"),
                        meta: {
                            title: "meta.button.newToken",
                            additionalLinks: [
                                {
                                    name: "meta.button.newToken",
                                    routeName: "token_new"
                                }
                            ]
                        }
                    },
                    {
                        path: "shops/:shopid/tokens/:id",
                        name: "token",
                        component: () => import("../views/DevPage.vue"),
                        meta: { title: "meta.title.settings.tokenDetails" }
                    },
                    {
                        path: "brands/:id",
                        name: "brand",
                        component: () => import("../views/DevPage.vue"),
                        meta: { title: "meta.title.settings.brandDetails" }
                    },
                    {
                        path: "email",
                        name: "settings_email",
                        component: () => import("../views/settings/email"),
                        meta: { title: "meta.title.settings.templates" }
                    },
                    {
                        path: "boxes",
                        name: "settings_boxes",
                        component: () => import("../views/settings/Boxes.vue"),
                        meta: {
                            title: "meta.title.settings.box",
                            additionalLinks: [
                                {
                                    name: "meta.button.newBox",
                                    routeName: "box_new"
                                }
                            ]
                        }
                    },
                    {
                        path: "boxes/new",
                        name: "box_new",
                        component: () => import("../views/settings/NewBox.vue"),
                        meta: {
                            title: "meta.button.newBox"
                        }
                    },
                    {
                        path: "integrations",
                        name: "settings_integrations",
                        component: () =>
                            import("../views/settings/Integrations.vue"),
                        meta: {
                            title: "meta.title.settings.integrations"
                        }
                    },
                    {
                        path: "api",
                        name: "settings_api",
                        component: () =>
                            import("../views/settings/ShopApi.vue"),
                        meta: {
                            title: "meta.title.settings.API"
                        }
                    },
                    // api - index all
                    // api/edit/:id - edit one
                    // api/connect/:code - new one by code
                    {
                        path: "api/edit/:id",
                        name: "settings_api_edit",
                        component: () =>
                            import("../views/settings/EditApiIntegration.vue"),
                        meta: {
                            title: "meta.title.settings.API"
                        }
                    },
                    {
                        path: "api/connect/:code",
                        name: "settings_api_connect",
                        component: () =>
                            import(
                                "../views/settings/ConnectApiIntegration.vue"
                            ),
                        meta: {
                            title: "meta.title.settings.API"
                        }
                    },
                    {
                        path: "support",
                        name: "settings_support",
                        component: () =>
                            import("../views/settings/Support.vue"),
                        meta: {
                            title: "meta.title.settings.support",
                            additionalLinks: [
                                {
                                    name: "meta.button.newTicket",
                                    routeName: "settings_support_ticket_new"
                                }
                            ]
                        }
                    },
                    {
                        path: "support/tickets/new",
                        name: "settings_support_ticket_new",
                        component: () =>
                            import("../views/settings/NewTicket.vue"),
                        meta: {
                            title: "meta.title.settings.new_ticket"
                        }
                    },
                    {
                        path: "support/:ticket",
                        name: "settings_support_ticket",
                        component: () => import("../views/settings/Ticket.vue"),
                        meta: {
                            title: "meta.title.settings.ticket"
                        }
                    }
                ]
            }
        ]
    },
    {
        path: "/email",
        component: () => import("../views/auth/Auth"),
        redirect: { name: "email_verify" },
        beforeEnter: authCheck,
        children: [
            {
                name: "email_verify",
                path: "verify/:id/:hash",
                component: () => import("../views/EmailVerify.vue")
            }
        ]
    },
    {
        name: "auth",
        path: "/",
        meta: { domains: "app.sjoeff.test" },
        component: () => import("../views/auth/Auth.vue"),
        redirect: { name: "login" },
        children: [
            {
                name: "login",
                path: "login",
                component: () => import("../views/auth/Login.vue"),
                beforeEnter: notAuthCheck
            },
            {
                name: "register",
                path: "register",
                component: () => import("../views/auth/Register.vue"),
                beforeEnter: notAuthCheck
            },
            {
                name: "complete_registration",
                path: "/auth/complete",
                component: () =>
                    import("../views/auth/CompleteRegistration.vue"),
                beforeEnter: authCheck
            }
        ]
    },
    ...shared
];

<template>
    <div v-if="loaded" class="d-flex h-100">
        <sidebar-component/>
        <div class="content-container d-flex flex-column">
            <header-component :meta="this.$route.meta"/>
            <div class="page-content">
                <router-view v-if="shopsLoaded && settingsLoaded"/>
                <spinner-component v-else/>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderComponent from "@/components/layout/AppHeader";
import SidebarComponent from "@/components/layout/AppSidebar";
import Service from "@/models/Service";
import Shop from "@/models/Shop";
import SpinnerComponent from "@/components/SpinnerComponent";
import Printer from "@/models/Printer";
import User from "@/models/User";
import MetaComponent from "@/components/layout/PageHeader";
import UserSettings from "@/models/UserSettings";
import Box from "@/models/Box";
import Brand from "../models/Brand";
import Package from "../models/Package";
import axios from "axios";
import Label from "../models/Label";

export default {
    data: () => ({
        shopsLoaded: false,
        settingsLoaded: false,
        loader: null,
        loaded: false,
        lastFetch: null
    }),
    components: {
        HeaderComponent,
        SpinnerComponent,
        SidebarComponent,
        MetaComponent
    },
    created() {
        console.log("Initializing app");
        this.loader = this.$loading.show({
            transition: "test",
            isFullPage: true
        });
    },
    mounted() {
        User.me().then(() => {
            if (Shop.all().length === 0 || Brand.all().length === 0) {
                console.log("No shops found, need to complete registration");
                this.$router.push({name: 'complete_registration'});
            }
            let shopPromise = Shop.index().then(() => {
                console.log("Shops loaded");
                this.shopsLoaded = true;
            });
            let printerPromise = Printer.printers()
                .then(() => {
                    console.log("Printers loaded");
                })
                .catch(() => {
                    console.log("Printer app is not running");
                });
            let settingsPromise = UserSettings.index().then(() => {
                console.log("UserSettings loaded");
                this.settingsLoaded = true;
            });
            let boxesPromise = Box.index().then(() => {
                console.log("Boxes loaded");
            });

            Promise.all([
                shopPromise,
                printerPromise,
                settingsPromise,
                boxesPromise
            ]).then(() => {
                this.loaded = true;
                setTimeout(() => {
                    this.loader.hide();
                }, 0.1 * 1000);
                console.log("---App init completed---");
                this.initializePackageWorker();
            });
        });
    },
    computed: {
        latestPackage() {
            return Package.query().orderBy('updated_at', 'desc').first();
        },
        packages() {
            return Package.all();
        }
    },
    methods: {
        initializePackageWorker() {
            this.lastFetch = new Date().toISOString();
            setTimeout(this.checkUpdates, 5000);
        },
        checkUpdates() {
            console.log(this.lastFetch)
            let currentFetch = this.lastFetch;
            axios.get('/api/packages/fetch', {params: {timestamp: this.lastFetch}})
                .then((resp) => {
                    Package.insert({data: resp.data.data.packages});
                    Label.insert({data: resp.data.data.labels});

                    // check if there are any new labels
                    if (resp.data.data.labels.length > 0) {
                        if (Printer.serverActive()) {

                            let labelsToPrint = [];

                            resp.data.data.labels.forEach(label => {
                                // check if label created after last fetch
                                if (new Date(label.created_at) >= new Date(currentFetch) && label.current_status_code == 2) {
                                    if (this.$store.state.entities.packages.autoPrintIds.includes(label.package.id)) {
                                        console.log("Auto printing label");
                                        labelsToPrint.push(label.package.id);
                                    }
                                }
                            });

                            if (labelsToPrint.length > 0) {
                                Package.commit((state) => {
                                    this.$set(state, 'autoPrintIds', [...state.autoPrintIds.filter(id => !labelsToPrint.includes(id))]);
                                })
                                let printingTimeout = setTimeout(() => {
                                    labelsToPrint.forEach(label => {
                                        Printer.print(label);
                                    });
                                }, 5000);

                                let multiple = labelsToPrint.length > 1;

                                this.$toast.info(
                                    (multiple ? (labelsToPrint.length + " new labels has been created") : "Created label for package: " + Package.find(labelsToPrint[0]).order_number)
                                    + "<br/><small>Printing labels automatically, click to cancel</small>",
                                    {
                                        duration: 5000,
                                        pauseOnHover: true,
                                        onClick: () => {
                                            clearTimeout(printingTimeout);
                                        }
                                    });
                            }
                        } else
                            console.log('Printer app is not running to print automatically');
                    }

                    setTimeout(this.checkUpdates, 15000);
                })
            this.lastFetch = new Date().toISOString();
            console.log(this.lastFetch)
        }
    }
};
</script>

<style scoped>
footer {
    height: 50px;
}

.sticky-top,
.sticky-xl-top {
    top: 10px;
}

.content-container {
    flex: 1 0 auto;
    max-width: calc(100% - 300px);
    background-color: #eef3fb;
}

.page-content {
    flex: 1 1 auto;
    overflow-y: auto;
    height: 0;
    padding: 20px 15px 0px 15px;
}
</style>

import { Model } from "@vuex-orm/core";

export default class Status extends Model {
    static entity = "statuses";

    static TYPE_NULL = 0;
    static TYPE_CREATED = 1;
    static TYPE_LABELS_CREATED = 2;
    static TYPE_COMPLETED = 3;
    static TYPE_CANCELLED = 4;

    static fields() {
        return {
            id: this.number(null),
            code: this.number(null),
            message: this.string(0),
            statusable_id: this.attr(null),
            statusable_type: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null)
        };
    }
}

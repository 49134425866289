import { Model } from "@vuex-orm/core";
import Setting from "./Setting";
import User from "./User";

export default class UserSettings extends Model {
    static entity = "user_settings";

    static fields() {
        return {
            id: this.number(),
            user_id: this.number(),
            code: this.string(),
            allowed_setting_value_id: this.attr(),
            unconstrained_value: this.attr(),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            user: this.belongsTo(User, "user_id", "id"),
            setting: this.belongsTo(Setting, "code", "code")
        };
    }

    static index() {
        return this.api().get("/api/auth/user/settings");
    }

    static save(settings) {
        return this.api().post("/api/auth/user/settings", settings);
    }

    static saveAll(settings) {
        return this.api().post(
            "/api/auth/user/settings",
            settings.map(setting => this.prepare(setting))
        );
    }

    /**
     * Prepare setting to update in api
     * @param code
     * @param allowedSettingValueId
     * @param unconstrainedValue
     * @param setting
     * @returns Object
     */
    static prepare({
        code,
        allowed_setting_value_id,
        unconstrained_value,
        setting
    }) {
        return {
            code,
            value: setting.constrained
                ? allowed_setting_value_id
                : unconstrained_value
        };
    }
}

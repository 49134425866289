import axios from "axios";
// import toast from 'vue-toast-notification';

import { CREATE, ERROR, REQUEST, SHOW, SUCCESS, UPDATE } from "../actions/api";

const uri = "/api/shippingDefault";

const state = {
    shippingDefault: [],
    status: ""
};

const getters = {
    isLoaded: state => state.status == "success" || state.status == "error",
    error: state => state.error
};

const mutations = {
    [REQUEST]: state => {
        state.status = "loading";
    },
    [SUCCESS]: state => {
        state.status = "success";
    },
    [ERROR]: state => {
        state.status = "error";
    },
    [CREATE]: (state, shippingDefault) => {
        state.shippingDefault = shippingDefault;
        state.status = "success";
    },
    [UPDATE]: (state, shippingDefault) => {
        state.shippingDefault = shippingDefault;
    }
};

const actions = {
    [UPDATE]: ({ commit }, { shippingDefault }) => {
        return new Promise((resolve, reject) => {
            axios
                .put(`${uri}`, shippingDefault)
                .then(resp => {
                    commit(UPDATE, shippingDefault);
                    resolve();
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    [SHOW]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit(REQUEST);
            axios
                .get(`${uri}`)
                .then(resp => {
                    commit(CREATE, resp.data);
                    resolve();
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};

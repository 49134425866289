import { Model } from "@vuex-orm/core";
import Address from "./Address";

export default class PickupPoint extends Model {
    static entity = "pickup_points";

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(""),
            integration: this.string(""),
            integration_uuid: this.string(""),
            address_id: this.attr(null),
            available_days: this.attr([]),
            rule_uuid: this.string(""),

            address: this.belongsTo(Address, "address_id")
        };
    }

    // Endpoints
    static addPickupPoint(integration_code, pickup_point_id) {
        // TODO: add integration to each pickup point
        return this.api().post("/api/pickup-points", {
            integration: integration_code,
            uuid: pickup_point_id
        });
    }
}

<template>
    <router-view />
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import User from "@/models/User";

export default {
    computed: {
        ...mapGetters(["isAuthenticated"]),
    },
    created() {
        let t = this;
        console.log("Add interceptor check");
        axios.interceptors.response.use(undefined, function (err) {
            if (err.response && err.response.status === 401) {
                console.log("Got unauthorized response, log out");
                // if you ever get an unauthorized, logout the user
                User.logout();
                if (t.$route.name !== "login") {
                    t.$router.push({ name: "login" });
                    //   Vue.$toast.error("Please log in to your account");
                }
            }
            return Promise.reject(err);
        });
    },
};
</script>

import { Model } from "@vuex-orm/core";
import Setting from "./Setting";

export default class AllowedSettingsValue extends Model {
    static entity = "allowed_setting_values";

    static fields() {
        return {
            id: this.number(),
            setting_id: this.number(),
            caption: this.string(),
            value: this.string(""),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            setting: this.belongsTo(Setting, "setting_id", "id")
        };
    }
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center", class: { "neu-box": _vm.status != null } },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.status != null
        ? _c("div", { staticClass: "pt-3 fw-bold" }, [
            _vm._v(_vm._s(_vm.status.loadingStatus))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loader m-auto" }, [
      _c("span", { staticClass: "elem" }),
      _vm._v(" "),
      _c("span", { staticClass: "elem" }),
      _vm._v(" "),
      _c("span", { staticClass: "elem" }),
      _vm._v(" "),
      _c("span", { staticClass: "elem" }),
      _vm._v(" "),
      _c("span", { staticClass: "elem" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
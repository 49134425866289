import Vue from "vue";
import Vuex from "vuex";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import authStore from "./modules/auth.store";
import rulesStore from "./modules/rules.store";
import brandStore from "./modules/brand.store";
import VuexORM from "@vuex-orm/core";
import Shop from "../models/Shop";
import Token from "../models/Token";
import Address from "../models/Address";
import shippingDefaultStore from "./modules/shippingDefault.store";
import Package from "../models/Package";
import Service from "../models/Service";
import Recipient from "../models/Recipient";
import Status from "../models/Status";
import Label from "../models/Label";
import Notification from "../models/Notification";
import packgo from "./modules/packgo.store";
import Item from "../models/Item";
import Printer from "../models/Printer";
import EmailTemplate from "../models/EmailTemplate";
import Brand from "../models/Brand";
import Setting from "../models/Setting";
import AllowedSettingsValue from "../models/AllowedSettingValue";
import User from "../models/User";
import UserSettings from "../models/UserSettings";
import axios from "axios";
import Box from "../models/Box";
import Rule from "../models/Rule";
import Portal from "../models/Portal";
import Integration from "../models/Integration";
import UserIntegrations from "../models/UserIntegrations";
import PickupPoint from "../models/PickupPoint";
import Ticket from "../models/Ticket";
import ApiIntegration from "../models/ApiIntegration";
import ApiIntegrationConfig from "../models/ApiIntegrationConfig";

Vue.use(Vuex);
VuexORM.use(VuexORMAxios, { axios });

// Create a new instance of Database.
const database = new VuexORM.Database();

// Register Models to Database.
database.register(Shop);
database.register(Token);
database.register(Address);
database.register(Package);
database.register(Service);
database.register(Printer);
database.register(Address);
database.register(Recipient);
database.register(Status);
database.register(Label);
database.register(Notification);
database.register(Item);
database.register(EmailTemplate);
database.register(Brand);
database.register(Setting);
database.register(AllowedSettingsValue);
database.register(User);
database.register(UserSettings);
database.register(Box);
database.register(Rule);
database.register(Portal);
database.register(Integration);
database.register(UserIntegrations);
database.register(PickupPoint);
database.register(Ticket);
database.register(ApiIntegration);
database.register(ApiIntegrationConfig);

export default new Vuex.Store({
    modules: {
        auth: authStore,
        brands: brandStore,
        rules: rulesStore,
        shippingDefaults: shippingDefaultStore,
        packgo: packgo
    },
    plugins: [VuexORM.install(database)]
});

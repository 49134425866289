// check if user is authenticated
// otherwise redirect to the login page
import User from "../models/User";
import Vue from "vue";

export function authCheck(to, from, next) {
    console.log(to);
    if (User.isAuthenticated()) {
        next();
        return;
    }
    Vue.$toast.error("You need to login first.");
    next({ name: "login", query: { from: to.path } });
}

export function notAuthCheck(to, from, next) {
    if (!User.isAuthenticated()) {
        next();
        return;
    }
    Vue.$toast.success("You are already authenticated.");
    next({ name: "hub" });
}

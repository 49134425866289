import { Model } from "@vuex-orm/core";
import Brand from "./Brand";

export default class Portal extends Model {
    static entity = "portals";

    static state() {
        return {
            current_page: 0,
            last_page: 0
        };
    }

    static fields() {
        return {
            id: this.uid(null),
            brand_id: this.attr(null),
            support_url: this.attr(null),
            return_policy_url: this.attr(null),
            enable_refunds: this.boolean(false),
            return_fee: this.number(0),
            is_active: this.boolean(false),
            max_days: this.number(14),
            refund_options: this.attr([]),
            delivery_options: this.attr([]),
            reasons: this.attr([]),
            services: this.attr([]),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            name: this.attr(null),
            primary_color: this.attr(null),
            secondary_color: this.attr(null),

            brand: this.belongsTo(Brand, "brand_id")
        };
    }

    static index() {
        return this.api().get("/api/portals");
    }

    static getByUrl(name) {
        return this.api().get(`/api/portals/${name}/info`);
    }

    static create(portal) {
        return this.api().post("/api/portals", portal);
    }

    static show(id) {
        return this.api().get(`/api/portals/${id}`);
    }

    static update(id, portal) {
        return this.api().put(`/api/portals/${id}`, portal);
    }

    static destroy(id) {
        return this.api().delete(`/api/portals/${id}`);
    }
}

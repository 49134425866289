import { Model } from "@vuex-orm/core";

export default class Token extends Model {
    static entity = "tokens";

    static fields() {
        return {
            id: this.attr(null),
            shop_id: this.attr(null),
            name: this.attr(""),
            client_id: this.attr(null),
            secret: this.attr(""),
            webhook_enabled: this.attr(false),
            webhook_url: this.attr("")
        };
    }

    static index(shop_id) {
        return this.api().get(`/api/shops/${shop_id}/tokens`);
    }

    static create(shop_id, token) {
        return this.api().post(`/api/shops/${shop_id}/tokens`, token);
    }

    static show(shop_id, id) {
        return this.api().get(`/api/shops/${shop_id}/tokens/${id}`);
    }

    static destroy(shop_id, id) {
        return this.api().get(`/api/shops/${shop_id}/tokens/${id}`);
    }

    static test(shop_id, id) {
        return this.api().get(`/api/shops/${shop_id}/tokens/${id}/test`);
    }
}

import { Model } from "@vuex-orm/core";

export default class EmailTemplate extends Model {
    static entity = "email_templates";

    static fields() {
        return {
            id: this.number(null),
            brand_id: this.number(null),
            type: this.number(null),
            enable: this.boolean(null),
            description: this.string(null),
            subject: this.string(null).nullable(),
            heading: this.string(null).nullable(),
            from_name: this.string(null).nullable(),
            body: this.string(null).nullable(),
            button: this.string(null).nullable(),
            created_at: this.attr(null),
            updated_at: this.attr(null)
        };
    }

    static index() {
        return this.api().get("/api/email-templates");
    }

    static update(id, template) {
        return this.api().put(`/api/email-templates/${id}`, template);
    }
}

import { Model } from "@vuex-orm/core";
import UserSettings from "./UserSettings";

export default class Setting extends Model {
    static entity = "settings";
    static primaryKey = "code";

    // "setting": {
    //     "code": "delete-old-packages",
    //     "label": "Delete old packages",
    //     "constrained": true,
    //     "default": 0
    // },

    static fields() {
        return {
            code: this.string(),
            label: this.string(""),
            default: this.attr(),
            constrained: this.boolean(true),
            type: this.string(),
            min: this.number(),
            max: this.number(),

            allowed_values: this.attr([]),
            user_setting: this.hasOne(UserSettings, "code", "code")
        };
    }
}

//         "id": 1,
//         "setting_group_id": 1,
//         "description": "Delete old packages",
//         "constrained": 1,
//         "type": "bool",
//         "min_value": null,
//         "max_value": null,

import { Model } from "@vuex-orm/core";

export default class Notification extends Model {
    static entity = "notifications";

    static fields() {
        return {
            id: this.attr(null),
            header: this.attr(""),
            body: this.attr(""),
            read_at: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null)
        };
    }

    static index() {
        return this.api().get("/api/notifications");
    }

    static create(notification) {
        return this.api().post("/api/notifications", notification);
    }

    static show(id) {
        return this.api().get(`/api/notifications/${id}`);
    }

    static update(id, notification) {
        return this.api().put(`/api/notifications/${id}`, notification);
    }

    static destroy(id) {
        return this.api().delete(`/api/notifications/${id}`);
    }
}

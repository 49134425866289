import axios from "axios";
// import toast from 'vue-toast-notification';
import { app } from "../../app";

import {
    CREATE,
    DELETE,
    ERROR,
    INDEX,
    REQUEST,
    SUCCESS,
    UPDATE
} from "../actions/api";

const uri = "/api/brands";
const uri_update = "/api/brand-update";

const state = {
    brands: [],
    status: "",
    error: "",
    selectedBrand: ""
};

const getters = {
    isLoaded: state => state.status == "success",
    error: state => state.error,
    brandid: state => state.selectedBrand
};

const mutations = {
    [REQUEST]: state => {
        state.status = "loading";
    },
    [SUCCESS]: (state, brands) => {
        state.status = "success";
        state.brands = brands;
    },
    [ERROR]: (state, err) => {
        state.status = "error";
        state.error = err;
    },
    [DELETE]: (state, brand) => {
        const i = state.brands.map(el => el.id).indexOf(brand.id);
        state.brands.splice(i, 1);
    },
    [CREATE]: (state, brand) => {
        state.brands = [...state.brands, brand];
    },
    [UPDATE]: (state, brand) => {
        let p = {};
        p = Object.assign(p, brand);

        const i = state.brands.map(el => el.id).indexOf(brand.id);

        state.brands.splice(i, 1, p);
    },
    selectBrand(state, brandid) {
        console.log("selecting brand " + brandid);
        state.selectedBrand = brandid;
    }
};

const actions = {
    [INDEX]: ({ commit }, querry = null) => {
        return new Promise((resolve, reject) => {
            commit(REQUEST);
            axios
                .get(uri, { params: querry })
                .then(resp => {
                    console.log(resp.data);
                    if (resp.data.length == 0) {
                        app.$toast.info("Let's create your first brand!");
                        app.$router.push({ name: "brand_new" });
                    } else {
                        if (!state.selectedBrand)
                            commit("selectBrand", resp.data[0].id);
                    }
                    commit(SUCCESS, resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    console.log(err);
                    commit(ERROR, err.response.data.error);
                    reject(err);
                });
        });
    },
    [UPDATE]: ({ commit }, brand) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${uri_update}/${pack.id}`, brand)
                .then(resp => {
                    commit(UPDATE, brand);
                    resolve();
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    [DELETE]: ({ commit }, brand) => {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${uri}/${brand.id}`)
                .then(resp => {
                    commit(DELETE, brand);
                    resolve();
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    [CREATE]: ({ commit }, brand) => {
        console.log("bb", brand);
        return new Promise((resolve, reject) => {
            axios
                .post(uri, brand)
                .then(resp => {
                    if (state.brands.length == 0) {
                        commit("selectBrand", resp.data[0]);
                    }
                    commit(CREATE, resp.data);
                    resolve();
                })
                .catch(err => {
                    console.log(err);
                    reject(err.response.data);
                });
        });
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};

<template>
    <header class="nav-menu">
        <!-- header nav -->
        <div class="nav-profile text-end d-flex">
            <div
                class="d-flex justify-content-between align-items-center page-header"
            >
                <h3 class="m-0">{{ $t(String(meta.title)) }}</h3>
                <router-link
                    v-for="(link, i) in meta.additionalLinks"
                    :key="i"
                    :to="{ name: link.routeName }"
                    class="btn btn-primary"
                >
                    {{ $t(link.name) }}
                </router-link>
            </div>
            <div class="d-flex">
                <router-link
                    v-tooltip="$t('header.tooltips.settings')"
                    :to="{ name: 'settings' }"
                    class="btn px-1 text-primary rounded-0 hover-rotate-30"
                >
                    <svg
                        id="Setting"
                        height="39.808"
                        viewBox="0 0 37.818 39.808"
                        width="37.818"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            id="Path"
                            d="M5.777,11.246A5.679,5.679,0,0,1,0,5.633,5.7,5.7,0,0,1,5.777,0a5.624,5.624,0,1,1,0,11.246"
                            fill="#132038"
                            transform="translate(13.156 14.291)"
                        />
                        <path
                            id="Path-2"
                            d="M37.28,24.621a4.582,4.582,0,0,0-1.648-1.572,3.079,3.079,0,0,1-1.261-1.274,3.626,3.626,0,0,1,1.3-4.976,4.035,4.035,0,0,0,1.505-5.633L35.816,8.817A4.2,4.2,0,0,0,30.1,7.3a3.927,3.927,0,0,1-5.126-1.373,3.077,3.077,0,0,1-.468-1.752,3.54,3.54,0,0,0-.549-2.11A4.284,4.284,0,0,0,20.336,0H17.468a4.368,4.368,0,0,0-3.6,2.07,3.552,3.552,0,0,0-.57,2.11,3.077,3.077,0,0,1-.468,1.752A3.907,3.907,0,0,1,7.725,7.3,4.229,4.229,0,0,0,1.989,8.817L.626,11.166A4.068,4.068,0,0,0,2.131,16.8a3.65,3.65,0,0,1,1.322,4.976,3.253,3.253,0,0,1-1.281,1.274A4.216,4.216,0,0,0,.545,24.621,3.97,3.97,0,0,0,.585,28.7l1.4,2.388a4.247,4.247,0,0,0,3.621,2.07,4.3,4.3,0,0,0,2.156-.6,3.157,3.157,0,0,1,1.79-.458A3.766,3.766,0,0,1,13.3,35.728a4.121,4.121,0,0,0,4.231,4.08H20.3a4.116,4.116,0,0,0,4.211-4.08,3.8,3.8,0,0,1,3.763-3.623,3.245,3.245,0,0,1,1.79.458,4.174,4.174,0,0,0,5.757-1.473L37.24,28.7a4.017,4.017,0,0,0,.041-4.08"
                            data-name="Path"
                            fill="#132038"
                            opacity="0.4"
                        />
                    </svg>
                </router-link>
                <button
                    v-tooltip="$t('header.tooltips.logout')"
                    class="btn px-2 text-primary"
                    @click="logout"
                >
                    <svg
                        id="Login"
                        height="38.431"
                        viewBox="0 0 36.509 38.431"
                        width="36.509"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            id="Fill_1"
                            d="M0,8.543A8.7,8.7,0,0,1,8.823,0h9.67a8.676,8.676,0,0,1,8.8,8.524v21.36a8.7,8.7,0,0,1-8.821,8.547H8.8A8.679,8.679,0,0,1,0,29.9V8.543Z"
                            data-name="Fill 1"
                            fill="#132038"
                            opacity="0.4"
                            transform="translate(9.216)"
                        />
                        <path
                            id="Fill_4"
                            d="M26.013,6.022,20.31.432a1.519,1.519,0,0,0-2.125,0,1.464,1.464,0,0,0,0,2.093l3.123,3.061H1.5a1.48,1.48,0,1,0,0,2.959H21.311l-3.123,3.063a1.464,1.464,0,0,0,0,2.093,1.517,1.517,0,0,0,2.125,0l5.7-5.59a1.461,1.461,0,0,0,0-2.1"
                            data-name="Fill 4"
                            fill="#132038"
                            transform="translate(0 12.144)"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </header>
</template>
<script>
import User from "@/models/User";

export default {
    props: {
        meta: Object
    },
    methods: {
        logout() {
            let loader = this.$loading.show({ transition: "test" });
            setTimeout(() => {
                User.logout()
                    .then(() => {
                        this.$router.push({ name: "login" });
                    })
                    .finally(() => {
                        loader.hide();
                    });
            }, 1000);
        }
    }
};
</script>
<style scoped>
.nav-menu {
    background-color: #fff;
    border: none;
    box-shadow: 10px 0 20px #d9d9d9;
    padding: 20px 30px;
    z-index: 9999;
}

.page-header {
    width: 100%;
    margin-right: 50px;
}
</style>
